import React from "react";
import {useTranslation} from "react-i18next";

export function Footer() {
  const {t} = useTranslation();

  return <footer>
    <div className="footer-inner">
      <div className="legal-links-wrapper">
        <div>{t('copyright')}</div>
        <div>
          <a href={t('imprint-link')}>{t('Imprint')}</a>
          <a href={t('tou-link')}>{t('Terms of Use')}</a>
          <a href={t('pp-link')}>{t('Privacy Policy')}</a>
        </div>
      </div>
      <div className="footer-logo" />
    </div>
  </footer>;
}