import React from 'react';
import './App.scss';
import useRequest, {Statuses} from "./use-request";
import {Loading} from "./Loading";
import {Subscriptions} from "./Subscriptions";
import {ErrorMessage} from "./ErrorMessage";
import { getSubscription} from "./requests";
import {fromResToSubData} from "./models";
import './i18n';
import {Footer} from "./Footer";

function App() {
  const url = new URL(window.location.href);
  const uid = url.searchParams.get('uid');
  const hash = url.searchParams.get('hash');
  const cpk = url.searchParams.get('cpk');
  const request = useRequest({url: getSubscription, params: {uid, hash, cpk}});

  const data = fromResToSubData(request.data).filter(item => item.subscribed);
  return (
    <div className="app">
      <div className="wrapper">
        <div className="logo"/>
        <div className="inner">
          {
            request.status === Statuses.inProgress &&
            <Loading/>
          }
          {
            request.status === Statuses.successful && uid && hash &&
            <Subscriptions data={data} uid={uid} hash={hash}/>
          }
          {
            (request.status === Statuses.failed || !hash || !uid) &&
            <ErrorMessage />
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
