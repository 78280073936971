import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./i18n/en.json"
import de from "./i18n/de.json"
import fr from "./i18n/fr.json"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en
      },
      de: {
        translation: de
      },
      fr: {
        translation: fr
      }
    }
  });

export default i18n;