import {List} from "./List";
import React, {useState} from "react";
import useRequest, {Statuses} from "./use-request";
import {postSubscription} from "./requests";
import {Loading} from "./Loading";
import {ErrorMessage} from "./ErrorMessage";
import {SuccessMessage} from "./SuccessMessage";
import {SubData} from "./models";
import {useTranslation} from 'react-i18next';

interface Props {
  hash: string
  uid: string
  data: SubData
}

export function Subscriptions({data, uid, hash}: Props) {
  const request = useRequest();
  const [list, setList] = useState(data);
  const {t} = useTranslation();

  const selectAll = () => {
    const list = [...data];
    list.forEach(item => item.subscribed = true);
    setList( list);
  };

  const unselectAll = () => {
    const list = [...data];
    list.forEach(item => item.subscribed = false);
    setList( list);
  };

  const channels = list.filter(item => !item.subscribed).map(item => item.id);
  const save = () => {
    request.send({url: postSubscription, method: 'POST', data: {
      hash,
      uid,
      channels
    }});
  };

  return <>
    {request.status === Statuses.init && <>
      <div className="description">
        {t('Instruction')}
        <br/><br/>
        {t('Instruction2')}
      </div>
      <List data={list} changeList={setList}/>
      <div className="list-controls">
        <button className="control" onClick={selectAll}>{t('Select All')}</button>
        <button className="control" onClick={unselectAll}>{t("Unselect All")}</button>
      </div>
      <div className="button-wrapper">
        <button className="save-button" disabled={channels.length === 0} onClick={save}>{t('save')}</button>
      </div>
    </>}
    {request.status === Statuses.successful && request.data && <SuccessMessage/>}
    {request.status === Statuses.inProgress && <Loading/>}
    {request.status === Statuses.failed && <ErrorMessage/>}
  </>;
}
