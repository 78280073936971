import React from "react";
import {Item} from "./Item";
import {SubData} from "./models";

interface Props {
  data: SubData
  changeList: (data: SubData) => void
}

export function List({data, changeList}: Props) {
  const changeSelection = (i: number) => {
    const list = [...data];
    list[i].subscribed = !list[i].subscribed;
    changeList( list);
  };
  return <div>
    {data.map(
      (item, index) => <Item
        item={item}
        key={item.id}
        changeSelection={() => changeSelection(index)
      }/>
    )}
  </div>
}