export type SubData = Array<SubItems>;

export interface SubItems {
  id: string,
  title: string,
  subscribed: boolean
}

function isSubItems(item: any): item is SubItems {
  return typeof item.id === 'string'&&
    typeof item.title === 'string' &&
    typeof item.subscribed === 'boolean';
}

export function fromResToSubData(data: any):SubData {
  if (data instanceof Array) {
    if (data.every(item => isSubItems(item))) {
      return data;
    }
  }
  return [];
}